<template>
  <svg
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <linearGradient
      id="a"
      gradientUnits="userSpaceOnUse"
      x1="-242.4"
      x2="805.96"
      y1="628.34"
      y2="-181.22"
    >
      <stop offset="0" stop-color="#4ac7b3" />
      <stop offset="1" stop-color="#0091fc" />
    </linearGradient>
    <path
      d="m331.26 12.42c-9.3 2.95-50.62 6-55.15 6-42.27 0-89-6.33-118.88-12.28-40.4-8.08-81.23 3.35-112.11 31.34-28.89 26.19-41.68 63.09-40.81 99.24 2.2 90.94 35.09 182.9 61.15 287.28 7.62 30.53 19.27 58.29 40.39 73.16 17 12 38.87 15.33 65.75 5.47 33.58-12.33 76.53-42 104.91-63.56 33.22 47.09 64.28 69.76 94.6 69 48.65-1.09 66.08-48.51 75-84.08 19.7-78.9 53.76-208.62 61.15-287.27 7.95-84.94-80.5-154.59-176-124.3zm-93.17 412.94c-9.23 6.58-36.47 26.92-59.46 38.79-30.81 15.9-52.11 9.48-61.57-3-6.4-8.42-13-22.33-18.78-45.38-14.87-59.52-42.28-148.24-54.28-219.55-6.92-41.17-5.85-55.62-6-62-.48-25.79 8.58-52.48 29.79-71.72 22.83-20.71 53-29.18 82.76-23.24a723.1 723.1 0 0 0 106 12.5c-29.83 20.85-46.19 34.3-79.77 25.69a16.92 16.92 0 1 0 -8.4 32.78c81.53 20.88 91.41-46.8 196.87-70.52 48.25-5 89.7 20.72 102.86 56.93 36.19 99.63-136.95 262.36-230.02 328.72zm175.17-9.56c-20.74 83-61.15 72-110.12 2.24 54.42-44.63 111.8-101.89 151.69-160.58-13.35 56.43-30.56 114.23-41.57 158.34z"
      fill="url(#a)"
    />
  </svg>
</template>
